.email-item {
    cursor: pointer;
    transition: background 0.3s ease;
    padding-left: 10px !important;
    padding-right: 10px !important;
    border-radius: 10px;
    margin-bottom: 5px;
}

.email-item:hover {
    background-color: #f0f0f0;
}

.email-item.selected {
    background-color: #e6f4ff;
}

.scrollable-list {
    height: calc(100vh - 90px);
    overflow-y: auto;
    margin-bottom: 0;
    margin-top: 0
}

.leftAlign {
    text-align: left;
}

.rightAlign {
    text-align: right;
}

.email-detail-no-selection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #dddddd;
    height: 100%;
}

.email-detail-no-selection svg {
    font-size: 250px;
}

.email-detail-no-selection span {
    font-size: 16px;
    margin-top: -30px;
    text-transform: uppercase;
    font-weight: 700;
    color: #bbbbbb;

}


.email-detail-card {
    margin: 10px 0;
    padding: 0 15px;
    border-bottom: 1px solid #ddd;
    border-radius: 3px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .13);
}

.email-detail-topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
}

.email-detail-infos {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.email-detail-statsbar {
    cursor: pointer;
    user-select: none;
}

.email-detail-statsbar div {
    display: flex;
    align-items: center;
    font-weight: bold;
}

.email-detail-statsbar svg {
    color: #4499ff;
    margin-right: 5px;
}

.email-detail-stats-full {
    max-height: 600px;
    transition: max-height 0.25s ease-in;
    margin-top: 10px;


}

.email-detail-stats-full.hidden {
    transition: max-height 0.15s ease-out;
    max-height: 0;
    overflow: hidden;
}

.email-detail-stats-full .loading {
}

.attachment-container {
    display: flex;
    flex-wrap: wrap;
}

.attachment-button {
    margin-right: 10px;
    margin-bottom: 10px;
    border: 1px solid #4499ff;
    border-radius: 10px;
    background-color: #e6f4ff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    font-size: 12px;
    padding: 10px;
    text-align: center;
    color: #282c34;
}

.attachment-button p {
    line-height: 12px;
}